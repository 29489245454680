/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:58:58
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-25 12:29:29
 */
import styled from 'styled-components'

const CategoriesWrapper = styled.div`
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
    h1, h2, h3, h4, h5, h6 {
        margin-top: .5rem;
    }
    .categories-title{
        font-family: mercury ssm a,mercury ssm b;
        font-weight: 400;
        margin-bottom: .5em;
        color: #555;
    }
    h3 {
        font-size: 1.25rem;
        line-height: 1.3em;
        margin-bottom: .5em;
    }
    ul{
        padding: .5rem 0 1rem;
        list-style: none;
    }
    li{
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
    a{
        color: #555;
    }
    a, a:visited {
        color: #821122;
        text-decoration: none;
        outline: 0;
    }
    h4{
        margin-bottom: 3px;
        line-height: 1em;
        font-size: .8rem;
        color: #333;
    }
`
export default CategoriesWrapper
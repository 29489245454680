/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:48:21
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-26 10:27:50
 */
import React from 'react'
import { Link } from "gatsby"
import CategoriesWrapper from './categoriesWrapper'
// import { rhythm, scale } from "../../utils/typography"
// import { useStaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"
import { rhythm, scale } from "../../utils/typography"
import * as d3 from 'd3'

const Categories = (props) => {
    
    const {currentPage,numPages,prevPage,nextPage,isFirst,isLast}=props
    let pagesShowItems=[]
    if(numPages<=5){
        pagesShowItems=d3.range(numPages).map(d=>d)
    }else {
        pagesShowItems=d3.range(5).map((d,i)=>{
            if(currentPage===0||currentPage===1||currentPage===2){
                return i
            }else if(currentPage===(numPages+1)||currentPage===(numPages)||currentPage===(numPages-1)){
                return numPages-5+i
            }else{
                return currentPage-3+i
            }
        })
    }
    console.log(pagesShowItems)
    return (
        <CategoriesWrapper className="page-links">
              <ul className="wp_page_numbers"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  listStyle: 'none',
                  padding: 0,
                }}
              >
                <li className="page_info">Page {currentPage} of {numPages}</li>
                {!isFirst && (
                  <Link to={prevPage} rel="prev"
                    style={{
                      margin: '0 3px',
                    }}
                  >
                    {'<'}
                  </Link>
                )}
                {Array.from(pagesShowItems, (index, i) => (
                  <li
                    className=""
                    key={`pagination-number${index + 1}`}
                    style={{
                      margin: '0 3px',
                    }}
                  >
                    <Link
                      to={`/${index === 0 ? '' : index + 1}`}
                      style={{
                        padding: rhythm(1 / 4),
                        textDecoration: 'none',
                        color: index + 1 === currentPage ? '#000' : '#000',
                        background: index + 1 === currentPage ? '#E0E0E0' : '',
                      }}
                    >
                      {index + 1}
                    </Link>
                  </li>
                ))}
                {!isLast && (
                  <Link to={nextPage} 
                  style={{
                    margin: '0 3px',
                  }}
                  rel="next">
                    {'>'}
                  </Link>
                )}
              </ul>
        </CategoriesWrapper>
    )
}

export default Categories

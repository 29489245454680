/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:48:21
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-25 08:57:32
 */
import React, { Fragment } from 'react'
import { Link } from "gatsby"
import CategoriesWrapper from './categoriesWrapper'
// import { rhythm, scale } from "../../utils/typography"
// import { useStaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"

const Categories = (props) => {
    return (
        <CategoriesWrapper id="header-wrapper">
            <h3 className="categories-title">
                所有分类
            </h3>
            <Fragment>
                <ul>
                {props.categories.map(category => (
                    <li key={category}>
                        <Link to={`/categories/${category}`}>
                            <h4>{category}</h4>
                        </Link>
                    </li>
                ))}
                </ul>
            </Fragment>
        </CategoriesWrapper>
    )
}

export default Categories

/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:48:21
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-24 21:58:18
 */
import React from "react"
import { Link } from "gatsby"
import HeaderWrapper from './headerWrapper'
import { rhythm } from "../../utils/typography"
// import { rhythm, scale } from "../../utils/typography"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
const Header = () => {
    // const rootPath = `${__PATH_PREFIX__}/`
    // let header

    // if (location.pathname === rootPath) {
    //     header = (
    //         <h1
    //             style={{
    //                 ...scale(1.5),
    //                 marginBottom: rhythm(1.5),
    //                 marginTop: 0,
    //             }}
    //         >
    //             <Link
    //                 style={{
    //                     boxShadow: `none`,
    //                     textDecoration: `none`,
    //                     color: `inherit`,
    //                 }}
    //                 to={`/`}
    //             >
    //                 {title}
    //             </Link>
    //         </h1>
    //     )
    // } else {
    //     header = (
    //         <h3
    //             style={{
    //                 fontFamily: `Montserrat, sans-serif`,
    //                 marginTop: 0,
    //             }}
    //         >
                
    //         </h3>
    //     )
    // }
    const data = useStaticQuery(graphql`
    query logoQuery {
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fixed(width: 250, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
    return (
        <HeaderWrapper id="header-wrapper">
            <div id="header" className="container">
                <div id="logo" className="four columns">
                    {/* <a href="https://flowingdata.com/"> */}
                    <Link
                        style={{
                            boxShadow: `none`,
                            textDecoration: `none`,
                            color: `inherit`,
                        }}
                        to={`/`}
                    >
                        <Image
                            fixed={data.logo.childImageSharp.fixed}
                            alt={'logo'}
                            style={{
                                marginRight: rhythm(1 / 2),
                                marginBottom: 0,
                                minWidth: 50,
                                height: 44,
                                width:160
                                // borderRadius: `100%`,
                            }}
                            imgStyle={{
                                // borderRadius: `50%`,
                            }}
                        />
                    </Link>
                </div>
                {/* <div id="nav" class="eight columns">
                    <ul>
                        <li><a href="https://flowingdata.com/about/">About</a></li>
                        <li><a href="https://flowingdata.com/membership/">Membership</a></li>
                        <li><a href="//flowingdata.com/category/tutorials/">
                            Tutorials</a>
                        </li>
                        <li class="hide-on-small"><a href="https://flowingdata.com/courses/">Courses</a></li>
                        <li class="social hide-on-small">
                            <a href="https://flowingdata.com/newsletter/"><img src="https://flowingdata.com/wp-content/themes/fd-modern-5-8-3/images/email-icon100.png"></a>
                            <a href="https://twitter.com/flowingdata" target="_blank"><img src="https://flowingdata.com/wp-content/themes/fd-modern-5-8-3/images/twitter-logo100.png"></a>
                            <a href="https://flowingdata.com/feed" target="_blank"><img src="https://flowingdata.com/wp-content/themes/fd-modern-5-8-3/images/rss-icon100.png"></a>
                        </li>
                    </ul>
                </div> */}
            </div>
        </HeaderWrapper>
    )
}

export default Header

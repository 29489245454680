/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:58:58
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-25 12:22:49
 */
import styled from 'styled-components'

const HeaderWrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc;
    & #header{
        font-size: 13px;
        color: #999;
    }
    & .container {
        position: relative;
        width: 1120px;
        margin: 0 auto;
        padding: 0;
    }
    & .container .columns {
        display: inline;
        margin-left: 15px;
        margin-right: 15px;
    }
    & .container .four.columns {
        width: 250px;
    }
    @media only screen and (max-width: 767px){
        .container {
            width: 100%;
        }
    }
`
export default HeaderWrapper